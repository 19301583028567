<template>
  <div class="default">
    <v-app-bar app :height="56" class="th-py-0 header" elevate-on-scroll>
      <header-logo height="24" :light="false" />
      <language-selector v-if="showLanguageSelect" />
    </v-app-bar>
    <v-main>
      <div class="container th-py-32">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderLogo from "../components/Layout/Header/HeaderLogo";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";

export default {
  name: "default",
  components: {
    LanguageSelector,
    HeaderLogo,
  },
  computed: {
    ...mapGetters(["showLanguageSelect"]),
  },
};
</script>
